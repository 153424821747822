module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-168544426-1","head":true,"respectDNT":true,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"gattararestaurant.com","anonymize":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gattara","short_name":"gattara","start_url":"/","background_color":"#365f56","theme_color":"#365f56","display":"minimal-ui","icon":"/Users/marie/work/_gattara/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5f925286f454b455351e7f09ef6df643"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
